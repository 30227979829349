import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { isBlank } from "@ember/utils";
import I18n from "discourse-i18n";
import ComboBox from "select-kit/components/combo-box";
export default class RelativeTimePicker extends Component {
    @tracked
    duration;
    @tracked
    selectedInterval;
    constructor(){
        super(...arguments);
        const usesHours1 = this.args.durationHours !== undefined;
        const usesMinutes1 = this.args.durationMinutes !== undefined;
        if (usesHours1 && usesMinutes1) {
            throw new Error("relative-time needs initial duration in hours OR minutes, both are not supported");
        }
        if (usesHours1) {
            this._setInitialDurationFromHours();
        } else {
            this._setInitialDurationFromMinutes();
        }
    }
    _roundedDuration(duration1) {
        const rounded1 = parseFloat(duration1.toFixed(2));
        // showing 2.00 instead of just 2 in the input is weird
        return rounded1 % 1 === 0 ? parseInt(rounded1, 10) : rounded1;
    }
    _setInitialDurationFromHours() {
        if (this.args.durationHours === null) {
            this.duration = this.args.durationHours;
            this.selectedInterval = "hours";
        } else if (this.args.durationHours >= 8760) {
            this.duration = this._roundedDuration(this.args.durationHours / 365 / 24);
            this.selectedInterval = "years";
        } else if (this.args.durationHours >= 730) {
            this.duration = this._roundedDuration(this.args.durationHours / 30 / 24);
            this.selectedInterval = "months";
        } else if (this.args.durationHours >= 24) {
            this.duration = this._roundedDuration(this.args.durationHours / 24);
            this.selectedInterval = "days";
        } else if (this.args.durationHours < 1) {
            this.duration = this._roundedDuration(this.args.durationHours * 60);
            this.selectedInterval = "mins";
        } else {
            this.duration = this.args.durationHours;
            this.selectedInterval = "hours";
        }
    }
    _setInitialDurationFromMinutes() {
        if (this.args.durationMinutes >= 525600) {
            this.duration = this._roundedDuration(this.args.durationMinutes / 365 / 60 / 24);
            this.selectedInterval = "years";
        } else if (this.args.durationMinutes >= 43800) {
            this.duration = this._roundedDuration(this.args.durationMinutes / 30 / 60 / 24);
            this.selectedInterval = "months";
        } else if (this.args.durationMinutes >= 1440) {
            this.duration = this._roundedDuration(this.args.durationMinutes / 60 / 24);
            this.selectedInterval = "days";
        } else if (this.args.durationMinutes >= 60) {
            this.duration = this._roundedDuration(this.args.durationMinutes / 60);
            this.selectedInterval = "hours";
        } else {
            this.duration = this.args.durationMinutes;
            this.selectedInterval = "mins";
        }
    }
    get durationMin() {
        return this.selectedInterval === "mins" ? 1 : 0.1;
    }
    get durationStep() {
        return this.selectedInterval === "mins" ? 1 : 0.05;
    }
    get intervals() {
        const count1 = this.duration ? parseFloat(this.duration) : 0;
        return [
            {
                id: "mins",
                name: I18n.t("relative_time_picker.minutes", {
                    count: count1
                })
            },
            {
                id: "hours",
                name: I18n.t("relative_time_picker.hours", {
                    count: count1
                })
            },
            {
                id: "days",
                name: I18n.t("relative_time_picker.days", {
                    count: count1
                })
            },
            {
                id: "months",
                name: I18n.t("relative_time_picker.months", {
                    count: count1
                })
            },
            {
                id: "years",
                name: I18n.t("relative_time_picker.years", {
                    count: count1
                })
            }
        ].filter((interval1)=>!this.args.hiddenIntervals?.includes(interval1.id));
    }
    get calculatedMinutes() {
        if (isBlank(this.duration)) {
            return null;
        }
        const duration1 = parseFloat(this.duration);
        switch(this.selectedInterval){
            case "mins":
                // we round up here in case the user manually inputted a step < 1
                return Math.ceil(duration1);
            case "hours":
                return duration1 * 60;
            case "days":
                return duration1 * 60 * 24;
            case "months":
                return duration1 * 60 * 24 * 30; // less accurate because of varying days in months
            case "years":
                return duration1 * 60 * 24 * 365; // least accurate because of varying days in months/years
        }
    }
    @action
    onChangeInterval(interval1) {
        this.selectedInterval = interval1;
        this.args.onChange?.(this.calculatedMinutes);
    }
    @action
    onChangeDuration(event1) {
        this.duration = event1.target.value;
        this.args.onChange?.(this.calculatedMinutes);
    }
    static{
        template(`
    <div class="relative-time-picker">
      <input
        {{on "change" this.onChangeDuration}}
        type="number"
        min={{this.durationMin}}
        step={{this.durationStep}}
        value={{this.duration}}
        id={{@id}}
        class="relative-time-duration"
      />
      <ComboBox
        @content={{this.intervals}}
        @value={{this.selectedInterval}}
        @onChange={{this.onChangeInterval}}
        class="relative-time-intervals"
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
