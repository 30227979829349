import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import { inject as service } from "@ember/service";
import { resolveAllShortUrls } from "pretty-text/upload-short-url";
import { ajax } from "discourse/lib/ajax";
import { loadOneboxes } from "discourse/lib/load-oneboxes";
import { cook } from "discourse/lib/text";
export default class CookText extends Component {
    @service
    siteSettings;
    @tracked
    cooked = null;
    constructor(owner1, args1){
        super(owner1, args1);
        this.loadCookedText();
    }
    @action
    async loadCookedText() {
        const cooked1 = await cook(this.args.rawText);
        this.cooked = cooked1;
    }
    @action
    calculateOffsetHeight(element1) {
        if (!this.args.onOffsetHeightCalculated) {
            return;
        }
        return this.args.onOffsetHeightCalculated(element1?.offsetHeight);
    }
    @action
    buildOneboxes(element1) {
        if (this.args.paintOneboxes && this.cooked !== null) {
            loadOneboxes(element1, ajax, this.args.topicId, this.args.categoryId, this.siteSettings.max_oneboxes_per_post, false // refresh
            );
        }
    }
    @action
    resolveShortUrls(element1) {
        resolveAllShortUrls(ajax, this.siteSettings, element1, this.args.opts);
    }
    static{
        template(`
    <div
      ...attributes
      {{didUpdate this.buildOneboxes this.cooked}}
      {{didUpdate this.resolveShortUrls this.cooked}}
      {{didUpdate this.calculateOffsetHeight this.cooked}}
      {{didUpdate this.loadCookedText @rawText}}
    >
      {{this.cooked}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
