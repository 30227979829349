import { template } from "@ember/template-compiler";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { empty, equal, notEmpty } from "@ember/object/computed";
import { inject as service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import GlimmerComponentWithDeprecatedParentView from "discourse/components/glimmer-component-with-deprecated-parent-view";
import concatClass from "discourse/helpers/concat-class";
import DiscourseURL from "discourse/lib/url";
import icon from "discourse-common/helpers/d-icon";
import deprecated from "discourse-common/lib/deprecated";
import I18n from "discourse-i18n";
import or from "truth-helpers/helpers/or";
const ACTION_AS_STRING_DEPRECATION_ARGS = [
    "DButton no longer supports @action as a string. Please refactor to use an closure action instead.",
    {
        id: "discourse.d-button-action-string"
    }
];
export default class DButton extends GlimmerComponentWithDeprecatedParentView {
    @service
    router;
    @notEmpty("args.icon")
    btnIcon;
    @equal("args.display", "link")
    btnLink;
    @empty("computedLabel")
    noText;
    constructor(){
        super(...arguments);
        if (typeof this.args.action === "string") {
            deprecated(...ACTION_AS_STRING_DEPRECATION_ARGS);
        }
    }
    get forceDisabled() {
        return !!this.args.isLoading;
    }
    get isDisabled() {
        return this.forceDisabled || this.args.disabled;
    }
    get btnType() {
        if (this.args.icon) {
            return this.computedLabel ? "btn-icon-text" : "btn-icon";
        } else if (this.computedLabel) {
            return "btn-text";
        }
    }
    get computedTitle() {
        if (this.args.title) {
            return I18n.t(this.args.title);
        }
        return this.args.translatedTitle;
    }
    get computedLabel() {
        if (this.args.label) {
            return I18n.t(this.args.label);
        }
        return this.args.translatedLabel;
    }
    get computedAriaLabel() {
        if (this.args.ariaLabel) {
            return I18n.t(this.args.ariaLabel);
        }
        if (this.args.translatedAriaLabel) {
            return this.args.translatedAriaLabel;
        }
    }
    get computedAriaExpanded() {
        if (this.args.ariaExpanded === true) {
            return "true";
        }
        if (this.args.ariaExpanded === false) {
            return "false";
        }
    }
    @action
    keyDown(e1) {
        if (this.args.onKeyDown) {
            e1.stopPropagation();
            this.args.onKeyDown(e1);
        } else if (e1.key === "Enter") {
            this._triggerAction(e1);
        }
    }
    @action
    click(event1) {
        return this._triggerAction(event1);
    }
    @action
    mouseDown(event1) {
        if (this.args.preventFocus) {
            event1.preventDefault();
        }
    }
    _triggerAction(event1) {
        const { action: actionVal1, route: route1, href: href1 } = this.args;
        if (actionVal1 || route1 || href1?.length) {
            if (actionVal1) {
                const { actionParam: actionParam1, forwardEvent: forwardEvent1 } = this.args;
                if (typeof actionVal1 === "string") {
                    deprecated(...ACTION_AS_STRING_DEPRECATION_ARGS);
                    if (this._target?.send) {
                        this._target.send(actionVal1, actionParam1);
                    } else {
                        throw new Error("DButton could not find a target for the action. Use a closure action instead");
                    }
                } else if (typeof actionVal1 === "object" && actionVal1.value) {
                    if (forwardEvent1) {
                        actionVal1.value(actionParam1, event1);
                    } else {
                        actionVal1.value(actionParam1);
                    }
                } else if (typeof actionVal1 === "function") {
                    if (forwardEvent1) {
                        actionVal1(actionParam1, event1);
                    } else {
                        actionVal1(actionParam1);
                    }
                }
            } else if (route1) {
                this.router.transitionTo(route1);
            } else if (href1?.length) {
                DiscourseURL.routeTo(href1);
            }
            event1.preventDefault();
            event1.stopPropagation();
            return false;
        }
    }
    static{
        template(`
    {{! template-lint-disable no-pointer-down-event-binding }}
    <button
      {{! For legacy compatibility. Prefer passing class as attributes. }}
      class={{concatClass
        @class
        (if @isLoading "is-loading")
        (if this.btnLink "btn-link" "btn")
        (if this.noText "no-text")
        this.btnType
      }}
      {{! For legacy compatibility. Prefer passing these as html attributes. }}
      id={{@id}}
      form={{@form}}
      aria-controls={{@ariaControls}}
      aria-expanded={{this.computedAriaExpanded}}
      tabindex={{@tabindex}}
      type={{or @type "button"}}
      ...attributes
      disabled={{this.isDisabled}}
      title={{this.computedTitle}}
      aria-label={{this.computedAriaLabel}}
      {{on "keydown" this.keyDown}}
      {{on "click" this.click}}
      {{on "mousedown" this.mouseDown}}
    >
      {{#if @isLoading}}
        {{~icon "spinner" class="loading-icon"~}}
      {{else}}
        {{#if @icon}}
          {{#if @ariaHidden}}
            <span aria-hidden="true">
              {{~icon @icon~}}
            </span>
          {{else}}
            {{~icon @icon~}}
          {{/if}}
        {{/if}}
      {{/if}}

      {{~#if this.computedLabel~}}
        <span class="d-button-label">
          {{~htmlSafe this.computedLabel~}}
          {{~#if @ellipsis~}}
            &hellip;
          {{~/if~}}
        </span>
      {{~else~}}
        &#8203;
        {{! Zero-width space character, so icon-only button height = regular button height }}
      {{~/if~}}

      {{yield}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
