import { template } from "@ember/template-compiler";
import ConditionalInElement from "../conditional-in-element";
const PanelPortal = template(`
  <ConditionalInElement @element={{@panelElement}}>
    {{yield}}
  </ConditionalInElement>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PanelPortal;
