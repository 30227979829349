import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"post.usernameUrl",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":29}}}))
    + "\" classNames=\"trigger-user-card "
    + alias2(lookupProperty(helpers,"get").call(alias1,"classNames",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":75}}}))
    + "\" data-user-card=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"post.username",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":93},"end":{"line":1,"column":110}}}))
    + "\">"
    + alias2((lookupProperty(helpers,"avatar")||(depth0 && lookupProperty(depth0,"avatar"))||container.hooks.helperMissing).call(alias1,"post",{"name":"avatar","hash":{"imageSize":"large"},"hashTypes":{"imageSize":"StringLiteral"},"hashContexts":{"imageSize":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":1,"column":112},"end":{"line":1,"column":145}}}))
    + "</a>\n";
},"useData":true});

addRawTemplate("post/poster-avatar", template, { core: true });
export default template;